import React, { useState, useEffect } from "react";
import "./Desktop.css";
import AppFolder from "./AppFolder";
import Window from "./Window";
import appsData from "./appsData";
import BottomBar from "./BottomBar";

function Desktop() {

  const [showWarning, setShowWarning] = useState(true);

  // Hide the warning after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWarning(false);
    }, 3000); // Change 3000 to the number of milliseconds you want the warning to show

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const [openWindows, setOpenWindows] = useState([]);

  const handleAppOpen = (app, isNestedAppWindow) => {
    const existingWindow = openWindows.find((window) => window.id === app.id);

    if (existingWindow) {
      // If the window for this app is already open, bring it to the front
      setOpenWindows((prevWindows) => [
        ...prevWindows.filter((window) => window.id !== app.id),
        existingWindow,
      ]);
    } else {
      // Check if it's a nested app and has a parent window
      if (isNestedAppWindow && app.parentWindowId) {
        setOpenWindows((prevWindows) =>
          prevWindows.map((window) => {
            if (window.id === app.parentWindowId) {
              // If the parent window of the nested app is found, add it to the nestedApps
              return {
                ...window,
                nestedApps: [...window.nestedApps, app],
              };
            } else {
              return window;
            }
          })
        );
      } else {
        // If it's not a nested app or doesn't have a parent window, open a new window
        const newWindow = {
          ...app,
          id: Date.now(),
          isNestedAppWindow: isNestedAppWindow,
        };
        setOpenWindows((prevWindows) => [...prevWindows, newWindow]);
      }
    }
  };

  const handleAppClose = (windowId) => {
    setOpenWindows((prevWindows) =>
      prevWindows.filter((window) => window.id !== windowId)
    );
  };

  // Function to handle switching between nested apps within a window
  const handleAppSwitch = (appToSwitch) => {
    setOpenWindows((prevWindows) =>
      prevWindows.map((window) => {
        if (window.id === appToSwitch.parentWindowId) {
          // If the parent window of the app to switch is found
          // Check if the app is already open in that window
          const existingApp = window.nestedApps.find(
            (app) => app.id === appToSwitch.id
          );

          if (existingApp) {
            // If the app is already open in the window, bring it to the front
            return {
              ...window,
              nestedApps: [
                existingApp,
                ...window.nestedApps.filter((app) => app.id !== appToSwitch.id),
              ],
            };
          } else {
            // If the app is not open in the window, add it to the nested apps
            return {
              ...window,
              nestedApps: [...window.nestedApps, appToSwitch],
            };
          }
        } else {
          return window;
        }
      })
    );
  };

  return (
    <div className="Desktop">
      
       {showWarning && <div className="warning">Double Click To Open Apps</div>} {/* Warning message */}
      <BottomBar openWindows={openWindows} />

      {appsData.map((app) => (
        <AppFolder key={app.id} app={app} onAppOpen={handleAppOpen} />
      ))}
      {openWindows.map((window) => (
        <Window
          key={window.id}
          id={window.id}
          content={window.content}
          initialPosition={window.position}
          onClose={handleAppClose}
          isNestedAppWindow={window.isNestedAppWindow}
          nestedApps={window.nestedApps}
          onAppSwitch={handleAppSwitch}
          onAppOpen={handleAppOpen} // Ensure that onAppOpen is correctly passed as a prop
        />
      ))}
    </div>
  );
}

export default Desktop;
