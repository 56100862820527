import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

const ScrollAnimation = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();
  const lastScrollY = useRef(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isScrollingDown = window.scrollY > lastScrollY.current;
        lastScrollY.current = window.scrollY;

        // Fade in when scrolling up and element is intersecting
        if (!isScrollingDown && entry.isIntersecting) {
          setIsVisible(true);
        }
        // Fade out when scrolling down and element is not intersecting
        else if (isScrollingDown && !entry.isIntersecting) {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const props = useSpring({ opacity: isVisible ? 1 : 0 });

  return (
    <animated.div style={props} ref={ref}>
      {children}
    </animated.div>
  );
};

export default ScrollAnimation;
