import React, { useState, useRef, useEffect } from "react";
import "./Window.css";

function Window({
  id,
  content,
  onClose,
  isNestedAppWindow,
  nestedApps,
  onAppOpen,
}) {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const [initialWidth, setInitialWidth] = useState(
    isMobile ? window.innerWidth : window.innerWidth * 0.5
  );
  const [initialHeight, setInitialHeight] = useState(
    isMobile ? window.innerHeight : window.innerHeight * 0.7
  );

  const positionRef = useRef({
    left: (window.innerWidth - initialWidth) / 2,
    top: (window.innerHeight - initialHeight) / 2,
  });
  const sizeRef = useRef({ width: initialWidth, height: initialHeight });

  const [position, setPosition] = useState(positionRef.current);
  const [size, setSize] = useState(sizeRef.current);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isActive, setIsActive] = useState(false);
  const [selectedTechStack, setSelectedTechStack] = useState("All");

  const techStacks = [
    "All",
    "JAVASCRIPT",
    "HTML",
    "CSS",
    "JAVA",
    "PYTHON",
    "C++",
    "C#",
    "REACT",
    "NODE JS",
    "MONGODB",
    "SQL",
    "FIREBASE",
    "FIRESTORE",
    "R Language",
    "API",
    "FLASK",
    "GoogleCloudPlatform",
    "KOTLIN",
    "SOCKETS",
    "FIGMA",
    "AI/ML",
    "GAME",
    "ELEMENTOR",
    "WORDPRESS",
    "EJS",
    "DESIGNPATTERNS",
    "PAPPLET",
    "JDBC",
    "CNN",
  ];

  const startDrag = (e) => {
    if (e.target.className.includes("resize-handle")) return;
    setOffset({
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    });
    setIsDragging(true);
    setIsActive(true);
  };

  const onDrag = (e) => {
    if (isDragging) {
      setPosition({
        left: e.clientX - offset.x,
        top: e.clientY - offset.y,
      });
    }
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  const startResize = (direction, e) => {
    e.stopPropagation();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = size.width;
    const startHeight = size.height;

    setIsActive(true);

    const doResize = (e) => {
      let newWidth = startWidth;
      let newHeight = startHeight;

      if (direction === "right") {
        newWidth = Math.max(100, startWidth + (e.clientX - startX));
      } else if (direction === "bottom") {
        newHeight = Math.max(100, startHeight + (e.clientY - startY));
      }

      setSize({
        width: newWidth,
        height: newHeight,
      });
    };

    const stopResize = () => {
      document.removeEventListener("mousemove", doResize);
      document.removeEventListener("mouseup", stopResize);
      setIsActive(false);
    };

    document.addEventListener("mousemove", doResize);
    document.addEventListener("mouseup", stopResize);
  };

  useEffect(() => {
    const handleResize = () => {
      setPosition({
        left: (window.innerWidth - size.width) / 2,
        top: (window.innerHeight - size.height) / 2,
      });

      setInitialWidth(window.innerWidth * 0.6);
      setInitialHeight(window.innerHeight * 0.3);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [size]);

  const filterNestedAppsByTechStack = (apps, techStack) => {
    if (techStack === "All") return apps;

    // Check for JAVA
    if (techStack === "JAVA") {
      return apps.filter(
        (app) =>
          app.techStack.includes("JAVA") &&
          !app.techStack.includes("JAVASCRIPT")
      );
    }

    // Check for JAVASCRIPT
    if (techStack === "JAVASCRIPT") {
      return apps.filter((app) => app.techStack.includes("JAVASCRIPT"));
    }

    // General case for other tech stacks
    return apps.filter((app) => app.techStack.includes(techStack));
  };

  return (
    <div
      className={`window ${isActive ? "active" : ""} ${
        isNestedAppWindow ? "nested-app-window" : ""
      }`}
      style={{
        left: `${position.left}px`,
        top: `${position.top}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
      }}
      onMouseUp={endDrag}
      onMouseMove={onDrag}
    >
      <div className="window-header" onMouseDown={startDrag}>
        <button className="close-button" onClick={() => onClose(id)}></button>
      </div>

      {isNestedAppWindow && (
        <div className="tech-stack-dropdown">
          <select
            value={selectedTechStack}
            onChange={(e) => setSelectedTechStack(e.target.value)}
          >
            {techStacks.map((stack) => (
              <option key={stack} value={stack}>
                {stack}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="window-content" style={{ overflow: "auto" }}>
        {isNestedAppWindow
          ? filterNestedAppsByTechStack(nestedApps, selectedTechStack).map(
              (app) => (
                <div
                  key={app.id}
                  className="nested-app-icon"
                  onClick={() => onAppOpen(app, false)}
                >
                  <img
                    src={app.iconSrc}
                    alt={`Nested App ${app.id} Icon`}
                    width="50"
                    height="50"
                  />
                  <div>{app.name}</div>
                </div>
              )
            )
          : content}
      </div>

      {!isNestedAppWindow && (
        <div>
          <div
            className="resize-handle right"
            onMouseDown={(e) => startResize("right", e)}
          ></div>
          <div
            className="resize-handle bottom"
            onMouseDown={(e) => startResize("bottom", e)}
          ></div>
        </div>
      )}

      {isNestedAppWindow && (
        <div>
          <div
            className="resize-handle right"
            onMouseDown={(e) => startResize("right", e)}
          ></div>
          <div
            className="resize-handle bottom"
            onMouseDown={(e) => startResize("bottom", e)}
          ></div>
        </div>
      )}
    </div>
  );
}

export default Window;
