import React, { useState, useEffect } from 'react';

import './AppFolder.css';

function AppFolder({ app, onAppOpen }) {
  const [isDragging, setIsDragging] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [position, setPosition] = useState(app.position);

  useEffect(() => {
    const globalMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
      }
    };

    if (isDragging) {
      // Add global event listener when dragging starts
      window.addEventListener('mouseup', globalMouseUp);
    }

    return () => {
      // Remove global event listener when dragging ends
      window.removeEventListener('mouseup', globalMouseUp);
    };
  }, [isDragging]); // Only re-run the effect if isDragging changes

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffsetX(e.clientX - position.left);
    setOffsetY(e.clientY - position.top);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const x = e.clientX - offsetX;
      const y = e.clientY - offsetY;
      setPosition({ left: x, top: y });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const folderStyle = {
    width: '100px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    left: `${position.left}px`,
    top: `${position.top}px`,
  };

  const handleClick = () => {
    if (app.linkUrl) {
      // If a link URL is provided, open the link in a new tab/window
      window.open(app.linkUrl, '_blank');
    } else if (app.nestedApps && app.nestedApps.length > 0) {
      // If the app has nested apps, open a window displaying them
      onAppOpen(app, true); // Pass a second argument to indicate that it's a nested app window
    } else {
      onAppOpen(app, false);
    }
  };

  const handleDoubleClick = () => {
    if (app.linkUrl) {
      // If a link URL is provided, open the link in a new tab/window
      window.open(app.linkUrl, '_blank');
    } else if (app.nestedApps && app.nestedApps.length > 0) {
      // If the app has nested apps, open a window displaying them
      onAppOpen(app, true); // Pass a second argument to indicate that it's a nested app window
    } else {
      onAppOpen(app, false);
    }
  };

  // Check if the screen width is less than a certain value (e.g., 768px) to determine if it's a mobile view
  const isMobile = window.innerWidth < 768;

  return (
    <div
      className="app-folder"
      style={folderStyle}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={isMobile ? handleClick : undefined} // Use onClick for mobile, undefined for non-mobile
      onDoubleClick={isMobile ? undefined : handleDoubleClick} // Use onDoubleClick for non-mobile, undefined for mobile
    >
      <img 
        src={app.iconSrc} 
        alt={`App ${app.id} Icon`} 
        width="50" 
        height="50"
        onDragStart={handleDragStart} // Prevent default drag behavior
      />
      <div>{app.name}</div>
    </div>
  );
}

export default AppFolder;
