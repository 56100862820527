import React, { useState, useEffect } from 'react';
import './BottomBar.css';

function BottomBar({ openWindows }) { // Change the prop name to openWindows
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString();
  const formattedDate = currentTime.toLocaleDateString();

  return (
    <div className="bottom-bar">
      {openWindows && openWindows.map((window) => ( // Check if openWindows is defined
        <div key={window.id} className="taskbar-item">
          {window.name}
        </div>
      ))}
      <div className="taskbar-time">{formattedTime}</div>
      <div className="taskbar-date">{formattedDate}</div>
    </div>
  );
}

export default BottomBar;
