import React, { useState } from "react";

const Gallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="gallery-container">
      <div className="gallery-image-container">
        <img
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex}`}
          className="gallery-image"
        />
      </div>
      <div className="arrow-buttons">
        <button onClick={prevImage} disabled={currentImageIndex === 0}>
          &#9664; {/* Unicode character for left arrow */}
        </button>
        <button onClick={nextImage} disabled={currentImageIndex === images.length - 1}>
          &#9654; {/* Unicode character for right arrow */}
        </button>
      </div>
    </div>
  );
};

export default Gallery;
