import React from "react";
import "./AppContent.css"; // Import the CSS file
import ScrollAnimation from "./ScrollAnimation";
import Gallery from "./Gallery";

const galleryImages = [
  "/images/loginProfilePic.jpg",
  "/images/Gallery/football.jpg",
  "/images/Gallery/snowboarding.jpg",
  "/images/Gallery/hiking.jpg",
  "/images/Gallery/mexico.jpg",
  "/images/Gallery/basketball.jpg",
];

const appsData = [
  {
    id: 3,
    name: "Projects",
    iconSrc: "/images/icons/projects.png", // Placeholder icon URL
    position: { left: 50, top: 150 },
    nestedApps: [
      {
        id: 31,
        name: "Alpha1Industrial",
        iconSrc: "/images/Alpha1Pics/logo.png", // Placeholder icon URL
        techStack: "JAVASCRIPT, HTML, CSS, REACT, NODE JS, FIGMA",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/Alpha1Pics/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Alpha 1 Industrial Services</h1>
                <h3>Professional Website Design and Creation</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://alpha1industrial.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  I proudly present the Alpha 1 Industrials Official Company
                  Website, a personal project that showcases my skills as a web
                  developer, designer, and project manager. As a solo developer,
                  I undertook the challenge of creating a fully customized
                  online platform for a company specializing in industrial
                  services, including machine moving, millwright services,
                  truck, and crane services. This endeavor highlights my
                  capabilities in web development and design.
                </p>

                <img src="/images/Alpha1Pics/landing.png" alt="Nested App 1" />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>REACT</li>
                  <li>NODE JS</li>
                  <li>FIGMA</li>
                  <li>A2HOSTING</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  As the sole developer, I was responsible for the complete
                  design and development of the Alpha 1 Industrial Services
                  website. My work included the creation of custom React
                  components, responsive design in Figma, and implementation of
                  a custom email service using Node.js. I ensured that the
                  website provided a dynamic, interactive, and smooth browsing
                  experience for users, while embodying the brand's identity and
                  values.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  This project enhanced my skills in JavaScript, React, CSS,
                  hosting and Node.js. Designing a responsive and visually
                  appealing layout in Figma was a significant learning
                  experience, as was managing backend services like the custom
                  email service. The project sharpened my abilities in
                  full-stack development and project management.
                </p>
              </section>

              {/* Placement of Remaining Images */}
              <h2>Preview:</h2>
              <div>
                <img src="/images/Alpha1Pics/gallery.png" />
                <img src="/images/Alpha1Pics/quote.png" />
                <img src="/images/Alpha1Pics/footer.png" />
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 32,
        name: "MahanStone",
        iconSrc: "/images/MahanStonePics/logo.png", // Placeholder icon URL
        techStack:
          "JAVASCRIPT, ELEMENTOR, WORDPRESS, FIGMA, GoogleCloudPlatform",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/MahanStonePics/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Mahan Counter Tops Website</h1>
                <h3>Redesign and Upgrade Professional Website</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://mahanstone.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  I am excited to present my project: the redesign and upgrade
                  of the Mahan Counter Tops company website. This undertaking
                  showcases my skills in web design, enhancement of
                  functionality, and the ability to create a more engaging
                  online presence. By leveraging the no-code system of WordPress
                  and Elementor, I transformed the website into a modern and
                  highly functional platform.
                </p>
                <img src="/images/MahanStonePics/home.png" />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>ELEMENTOR</li>
                  <li>WORDPRESS</li>
                  <li>FIGMA</li>
                  <li>GoogleCloudPlatform</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  I led the redesign and technical upgrade of the Mahan Counter
                  Tops website, implementing a modern look and enhanced
                  functionality. My contributions included using WordPress and
                  Elementor for no-code design, integrating Google Workspaces
                  Calendar for booking, and enhancing certain pages with jQuery
                  and JavaScript for a dynamic user experience.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  This project deepened my expertise in WordPress, Elementor,
                  and Figma. I also gained valuable experience in integrating
                  third-party services like Google Workspace and applying
                  JavaScript and jQuery for interactive web elements. This
                  project honed my skills in web design and user experience
                  enhancement.
                </p>
              </section>

              {/* Placement of Remaining Images */}
              <h2>Preview:</h2>
              <div>
                <img src="/images/MahanStonePics/home.png" />
                <img src="/images/MahanStonePics/gallery.png" />
                <img src="/images/MahanStonePics/quote.png" />
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 33,
        name: "DAC",
        iconSrc: "/images/DAC/logo.png", // Placeholder icon URL
        techStack: "JAVASCRIPT, HTML, CSS, API, FIREBASE, FIRESTORE",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/DAC/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Destination Alarm Clock</h1>
                <h3>Web Application</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://dac-destination-alarm-clock.web.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Web App
                </a>
                <a
                  href="https://github.com/LaurieSolkoski/Destination_Alarm_Clock_COMP1800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  Destination Alarm Clock is a collaborative project undertaken
                  by a dedicated team consisting of myself, Alex, and Laurie.
                  This innovative web application was designed to provide a
                  practical solution for commuters, such as students and
                  workers, who rely on public transit to reach their
                  destinations. The app allows users to select a destination,
                  set a radius, and configure an alarm. When they approach their
                  chosen destination within the specified radius, the alarm will
                  alert them, ensuring they never miss their stop.
                </p>
                <img src="/images/DAC/placed.png" alt="DAC App Preview 1" />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>API</li>
                  <li>FIREBASE</li>
                  <li>FIRESTORE</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  In this project, my role was pivotal in developing the
                  front-end using JavaScript, HTML, and CSS. I also contributed
                  to integrating and configuring the Leaflet API for the
                  interactive map feature. My involvement in Firebase and
                  Firestore setup helped streamline database management and
                  hosting.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  Working on DAC enhanced my skills in front-end development,
                  particularly with JavaScript, HTML, and CSS. I gained
                  practical experience in API integration and learned valuable
                  lessons in working with Firebase and Firestore for backend
                  management. This project further developed my ability to
                  collaborate effectively within a team.
                </p>
              </section>

              {/* Placement of Remaining Images */}
              <h2>Preview:</h2>
              <div>
                <img src="/images/DAC/search.png" alt="DAC App Preview 2" />
                <img src="/images/DAC/settings.png" alt="DAC App Preview 3" />
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 34,
        name: "WrightIntel",
        iconSrc: "/images/WrightIntel/logo.png", // Placeholder icon URL
        techStack:
          "JAVASCRIPT, HTML, CSS, EJS, NODE JS, PYTHON, MONGODB, API, AI/ML, FIGMA, FLASK",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/WrightIntel/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Wright Intel</h1>
                <h3>Innovative AI-Driven Air Passenger Chatbot</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://tldr-node.onrender.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Web App
                </a>
                <a
                  href="https://github.com/BardiaTiM/wrightIntel-TLDR-AI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  Our collaborative team, consisting of James, Jayden, Will, and
                  myself, embarked on an ambitious project to create an
                  innovative web application featuring an Artificial
                  Intelligence-driven chatbot. The chatbot, powered by the Chat
                  GPT API and Aero API, is designed to revolutionize the air
                  travel experience for passengers. Our primary goal is to
                  empower air travelers by providing them with a deep
                  understanding of their rights and simplifying the complexities
                  of airline policies. Through cutting-edge technology and
                  user-centric design, we are committed to making air travel
                  more accessible and less intimidating.
                </p>
                <img
                  src="/images/WrightIntel/landing.png"
                  alt="Wright Intel App Preview 1"
                />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>EJS</li>
                  <li>NODE JS</li>
                  <li>PYTHON</li>
                  <li>MONGODB</li>
                  <li>API</li>
                  <li>AI/ML</li>
                  <li>FIGMA</li>
                  <li>FLASK</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  In this project, I was heavily involved in AI development,
                  using Python and AI/ML technologies. I contributed to the
                  front-end development of the chat interface and managed
                  aspects of the MongoDB database. My role was crucial in
                  ensuring the AI's accuracy in data interpretation and
                  providing a seamless user experience on the front-end.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  This project significantly enhanced my understanding of AI and
                  machine learning. I improved my skills in Python, JavaScript,
                  and database management. Working on this project also taught
                  me valuable lessons in collaborative teamwork and the
                  integration of AI into web applications.
                </p>
              </section>

              {/* Placement of Remaining Images and Video */}
              <h2>Preview:</h2>
              <div>
                <img
                  src="/images/WrightIntel/chat.png"
                  alt="Wright Intel App Preview 2"
                />
                <img
                  src="/images/WrightIntel/account.png"
                  alt="Wright Intel App Preview 3"
                />
                <img
                  src="/images/WrightIntel/landinglight.png"
                  alt="Wright Intel App Preview 4"
                />
                <img
                  src="/images/WrightIntel/settings.png"
                  alt="Wright Intel App Preview 5"
                />
                <div className="video-container">
                  <video controls>
                    <source
                      src="/images/WrightIntel/demo.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 35,
        name: "DungeonQuad",
        iconSrc: "/images/DungeonQuad/logo.png", // Placeholder icon URL
        techStack: "JAVA, PAPPLET, DESIGNPATTERNS, MONGODB, GAME",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/DungeonQuad/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Dungeon Quad</h1>
                <h3>Bullet Hell Java Game</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a href="/images/DungeonQuad/DungeonQuad.zip" download>
                  Download Game
                </a>
                <a
                  href="https://download.oracle.com/java/17/archive/jdk-17.0.9_windows-x64_bin.exe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Java 17
                </a>
                <a
                  href="https://github.com/BardiaTiM/project-dungeon-quad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  A thrilling and collaborative project came to life when
                  myself, Ean, and Will joined forces to create an engaging Java
                  game using PApplet. This action-packed bullet hell game is a
                  testament to our combined creativity, technical skills, and
                  passion for game development. With an array of design patterns
                  such as Singleton, Facade, Strategy, Mediator, and more, our
                  objective was to deliver an immersive gaming experience that
                  challenges players and keeps them coming back for more.
                </p>
                <img
                  src="/images/DungeonQuad/menu.png"
                  alt="Dungeon Quad App Preview 1"
                />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVA</li>
                  <li>PAPPLET</li>
                  <li>DESIGNPATTERNS</li>
                  <li>MONGODB</li>
                  <li>GAME</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  My role in the Dungeon Quad project was primarily in gameplay
                  mechanics development, focusing on features like shooting,
                  player movement, enemy spawning, and health/damage systems. I
                  was also involved in the frontend/backend integration,
                  debugging, and implementing various design patterns to
                  optimize game functionality.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  Working on Dungeon Quad greatly enhanced my skills in Java and
                  game development. I gained a deep understanding of design
                  patterns and their practical applications in complex game
                  systems. This project also improved my abilities in database
                  integration, specifically with MongoDB, and in developing
                  engaging user interfaces.
                </p>
              </section>

              {/* Placement of Remaining Images */}
              <h2>Preview:</h2>
              <div>
                <img
                  src="/images/DungeonQuad/second.png"
                  alt="Dungeon Quad App Preview 2"
                />
                <img
                  src="/images/DungeonQuad/leaderboard.png"
                  alt="Dungeon Quad App Preview 3"
                />
                <img
                  src="/images/DungeonQuad/three.png"
                  alt="Dungeon Quad App Preview 4"
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 36,
        name: "Polycodia",
        iconSrc: "/images/Polycodia/logo.jpg", // Placeholder icon URL
        techStack: "JAVASCRIPT, HTML, CSS, FIGMA, R Language, API",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/Polycodia/logo.jpg" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Polycodia/Teck Resources QDS</h1>
                <h3>QDS Hackathon Finalist Project</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://github.com/LaurieSolkoski/PolycodiaHackathon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  The QDS Hackathon Project stands as a testament to my early
                  foray into hackathons and the world of data-driven innovation.
                  This project, which made it to the finals, was a response to
                  the challenge posed by Teck Mining Company to reduce fuel
                  usage and waste within their operations. Armed with 8GB of raw
                  data and an unyielding spirit, our team embarked on a mission
                  to tackle this pressing issue.
                </p>
              </section>

              {/* Solution */}
              <section>
                <h2>Solution:</h2>
                <p>
                  To address the issue of fuel waste in Teck's mining
                  operations, our team developed an application with dual
                  interfaces: one for truck drivers and one for project
                  management. The driver interface could detect when a truck was
                  idle and prompt the driver to specify the reason. The project
                  management dashboard provided real-time data on truck
                  locations and idleness reasons. This solution was aimed at
                  reducing unnecessary idle time and consequently fuel waste.
                </p>
              </section>
              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>FIGMA</li>
                  <li>R Language</li>
                  <li>API</li>
                </ul>
              </section>

              {/* Challenges and Achievements Section */}
              <section>
                <h2>Challenges and Achievements:</h2>
                <p>
                  The QDS Hackathon Project was both thrilling and challenging.
                  We had 48 hours to decipher 8GB of data and create an
                  effective solution. Our success in doing so showcased our
                  adaptability, problem-solving skills, and the power of
                  data-driven innovation. This project was a significant
                  achievement in our journey of learning and creativity.
                </p>
              </section>

              {/* Placement of Video and Demo */}
              <h2>Preview:</h2>
              <div className="video-container">
                <video controls>
                  <source src="/images/Polycodia/demo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="iframe-container">
                <iframe
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQb40vY8BpMHVWXLWJUixc7%2FUntitled%3Fnode-id%3D42-2%26starting-point-node-id%3D46%253A11%26scaling%3Dcontain%26mode%3Ddesign%26t%3DVjAM7KDlQk3CAjOr-1"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 37,
        name: "UrgencyAgent",
        iconSrc: "/images/UrgencyAgentPics/logo.jpeg", // Placeholder icon URL
        techStack: "JAVASCRIPT, HTML, CSS, API, FIREBASE, FIRESTORE",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img
                src="/images/UrgencyAgentPics/logo.jpeg"
                className="toplogo"
              />

              {/* Project Header and Description */}
              <header>
                <h1>Urgency Agent</h1>
                <h3>Automatic Task Planner Calendar</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://github.com/BardiaTiM/UrgencyAgent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  Urgency Agent holds a special place in my journey as a web
                  developer, as it represents one of my earliest projects. This
                  web-based calendar application, built with HTML, JavaScript,
                  and CSS, offers a unique solution to the time management
                  challenges faced by busy individuals. It simplifies the task
                  of planning and prioritizing tasks, making it an invaluable
                  tool for efficient time management.
                </p>
                <img
                  src="/images/UrgencyAgentPics/main.png"
                  alt="Urgency Agent App Preview 1"
                />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>API</li>
                  <li>FIREBASE</li>
                  <li>FIRESTORE</li>
                </ul>
              </section>

              {/* Key Features Section */}
              <section>
                <h2>Key Features:</h2>
                <ul>
                  <li>Task Scheduling</li>
                  <li>Urgency Sorting</li>
                  <li>Urgency Measurement</li>
                  <li>FullCalendar API Integration</li>
                  <li>Firebase Firestore for Data Storage</li>
                </ul>
              </section>

              {/* Placement of Video and Additional Images */}
              <h2>Preview:</h2>
              <div className="video-container">
                <video controls>
                  <source
                    src="/images/UrgencyAgentPics/demo.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div>
                <img
                  src="/images/UrgencyAgentPics/mainWithActivities.png"
                  alt="Urgency Agent App Preview 2"
                />
                <img
                  src="/images/UrgencyAgentPics/week.png"
                  alt="Urgency Agent App Preview 3"
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 38,
        name: "Jeoparody",
        iconSrc: "/images/Jeoparody/logo.png", // Placeholder icon URL
        techStack:
          "JAVA, HTML, CSS, SQL, JDBC, FIGMA, GAME, SOCKETS, DESIGNPATTERNS",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/Jeoparody/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Jeoparody</h1>
                <h3>Java Quiz Application</h3>
              </header>

              {/* Nested App Links */}
              <div className="nested-app-links">
                <a
                  href="https://github.com/RavdeepAulakh/Jeoparody"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  Jeoparody is an exciting and educational project that
                  reimagines the classic game show "Jeopardy." Developed as a
                  group project, we harnessed the power of various technologies,
                  including Java Servlets, SQL, JDBC, JavaScript, HTML, and CSS,
                  to create an engaging and interactive game experience.
                  Jeoparody offers both single-player and multiplayer modes,
                  making it a versatile and entertaining application for users
                  of all ages.
                </p>
                <img
                  src="/images/Jeoparody/main.png"
                  alt="Jeoparody App Preview 1"
                />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>JAVA</li>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>SQL</li>
                  <li>JDBC</li>
                  <li>FIGMA</li>
                  <li>GAME</li>
                  <li>SOCKETS</li>
                  <li>DESIGNPATTERNS</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contributions:</h2>
                <p>
                  In the development of Jeoparody, my primary responsibilities
                  revolved around the mechanics and logic of the quiz itself. I
                  designed and implemented the core gameplay, ensuring that
                  questions were presented accurately and that scoring was
                  handled correctly. Additionally, I played a pivotal role in
                  gathering and managing data for the quiz questions, helping to
                  curate a diverse and engaging question pool. I also took on
                  the task of fixing and maintaining the SQL database that
                  stored crucial game data.
                </p>
              </section>

              {/* Design Focus Section */}
              <section>
                <h2>Design Focus:</h2>
                <p>
                  A noteworthy aspect of Jeoparody is its user-centric design,
                  with a special emphasis on the comfort and accessibility of
                  elderly users. The custom design of the application takes into
                  consideration the needs of this demographic, ensuring a
                  user-friendly and comfortable experience for all.
                </p>
              </section>

              {/* Placement of Remaining Images and Video */}
              <h2>Preview:</h2>
              <div>
                <img
                  src="/images/Jeoparody/quiz.png"
                  alt="Jeoparody App Preview 2"
                />
                <div className="video-container">
                  <video controls>
                    <source src="/images/Jeoparody/demo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 39,
        name: "Nebula",
        iconSrc: "/images/Nebula/logo.png", // Placeholder icon URL
        techStack: "PYTHON, JAVASCRIPT, HTML, CSS, CNN, AI/ML",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/Nebula/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Nebula</h1>
                <h3>AI For Recognizing Instruments In Songs</h3>
              </header>

              <div className="nested-app-links">
                <a
                  href="https://github.com/gathrean/Nebula"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  Nebula stands as a pioneering research project at the
                  intersection of artificial intelligence and audio signal
                  processing. This project emerges to address the gap in
                  multi-musical instrument recognition with a groundbreaking
                  methodology using convolutional neural networks (CNN).
                </p>
                <img src="/images/Nebula/main.png" alt="Nebula App Preview 1" />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Technology Stack:</h2>
                <ul>
                  <li>PYTHON</li>
                  <li>JAVASCRIPT</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>CNN</li>
                  <li>AI/ML</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contributions:</h2>
                <p>
                  My role in Nebula was multifaceted, creating and managing a
                  custom dataset, and designing the web page to present our
                  research findings. But my primary contribution was in the
                  development and training of the model. I was in charge of
                  creating an accurate model that could recognize instruments in
                  songs with high accuracy.
                </p>
              </section>

              {/* Key Achievements Section */}
              <section>
                <h2>Key Achievements:</h2>
                <p>
                  Nebula's contribution to audio signal processing is marked by
                  key achievements such as instrument recognition with high
                  accuracy, an impressive 80% accuracy rate, and an F1 score of
                  49%, establishing a new standard in multi-instrument
                  recognition.
                </p>
              </section>

              {/* Placement of Video and Additional Images */}
              <h2>Preview:</h2>
              <div className="video-container">
                <video controls>
                  <source src="/images/Nebula/demo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 40,
        name: "Hokage Recipe",
        iconSrc: "/images/HokageRecipe/logo.png", // Placeholder icon URL
        techStack: "KOTLIN, API, JSON",
        content: (
          <div className="nested-app-card">
            <div className="project-section">
              {/* Logo */}
              <img src="/images/HokageRecipe/logo.png" className="toplogo" />

              {/* Project Header and Description */}
              <header>
                <h1>Hokage Recipe</h1>
                <h3>Food Recipe App in Kotlin</h3>
              </header>

              <div className="nested-app-links">
                <a
                  href="https://github.com/BardiaTiM/HokageRecipeApp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              </div>

              {/* Overview */}
              <section>
                <h2>Overview:</h2>
                <p>
                  In this personal project developed in Kotlin, I gained
                  valuable experience working with APIs and connecting them to
                  my application. The app, called Hokage Recipe, allows users to
                  search for various foods and provides step-by-step
                  instructions on how to make them, along with a list of
                  ingredients. This project involved the use of Coroutines,
                  Composables, and extracting information from JSON in a usable
                  format. I also utilized hoisting techniques, Nav Controllers,
                  Remember, NavHost, and Enums to create a seamless and
                  user-friendly experience for food enthusiasts.
                </p>

                <img
                  src="/images/HokageRecipe/home.png"
                  alt="Hokage Recipe Screenshots"
                />
              </section>

              {/* Tech Stack Section */}
              <section>
                <h2>Tech Stack:</h2>
                <ul>
                  <li>Kotlin</li>
                  <li>Android Studio</li>
                  <li>API</li>
                  <li>Coroutines</li>
                  <li>Jetpack Compose</li>
                  <li>JSON</li>
                </ul>
              </section>

              {/* My Contribution Section */}
              <section>
                <h2>My Contribution:</h2>
                <p>
                  As the sole developer of Hokage Recipe, I was responsible for
                  the entire project, from concept to implementation. My role
                  included designing the user interface using Jetpack Compose,
                  integrating API calls, implementing Coroutines for
                  asynchronous tasks, and structuring the navigation flow with
                  Nav Controllers and NavHost. Additionally, I handled the
                  extraction of relevant data from JSON responses, ensuring that
                  the app presented a seamless and informative experience for
                  users.
                </p>
              </section>

              {/* Learning Outcomes Section */}
              <section>
                <h2>Learning Outcomes:</h2>
                <p>
                  This project significantly enhanced my skills in Kotlin
                  development for Android applications. I gained practical
                  experience in working with APIs and handling JSON data
                  effectively. Jetpack Compose improved my UI design
                  capabilities, while Coroutines helped me manage asynchronous
                  tasks efficiently. Learning to navigate and utilize Nav
                  Controllers and NavHost for app navigation was another
                  valuable skill acquired during this project. Overall, Hokage
                  Recipe enriched my expertise in mobile app development and
                  Kotlin programming.
                </p>
              </section>

              {/* Placement of Remaining Images */}
              <h2>Preview:</h2>
              <div>
                <img src="/images/HokageRecipe/recipe.png" />
                <img src="/images/HokageRecipe/list.png" />
              </div>
            </div>
          </div>
        ),
      },
    ],
    content: (
      <div className="project-card">
        <img src="/images/project3-image.png" alt="Project 3" />
        <h2>Project 3 Title</h2>
        <p>Description of Project 3...</p>
        <p>
          <strong>Tech Stack:</strong> Angular, Spring Boot, PostgreSQL
        </p>
        <div className="project-links">
          <a
            href="https://example.com/project3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit App
          </a>
          <a
            href="https://youtube.com/project3-video"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch Video
          </a>
        </div>
      </div>
    ),
  },
  {
    id: 4,
    position: { left: 50, top: 350 },
    name: "LinkedIn",
    iconSrc: "/images/icons/linkedin.png",
    linkUrl: "https://www.linkedin.com/in/bardia-timouri-979b87226/", // Add the link URL here
  },
  {
    id: 5,
    position: { left: 50, top: 450 },
    name: "GitHub",
    iconSrc: "/images/icons/github.png",
    linkUrl: "https://github.com/BardiaTiM", // Add the link URL here
  },
  {
    id: 6,
    position: { left: 50, top: 550 },
    name: "Email",
    iconSrc: "/images/icons/email.png",
    linkUrl: "mailto:bardiatimouri@gmail.com", // Add the link URL here
  },
  {
    id: 2,
    name: "Resume",
    iconSrc: "/images/icons/resume.png", // Placeholder icon URL
    position: { left: 50, top: 250 },
    content: (
      <div className="project-card">
        <div className="project-links">
          <a
            href="/Bardia_Timouri_Resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Full Screen
          </a>

          <a
            href="/Bardia_Timouri_Resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Download
          </a>
        </div>
        <br></br>
        <iframe
          src="/Bardia_Timouri_Resume.pdf"
          width="100%"
          height="600"
          style={{ zoom: "0.8" }} // Adjust the zoom level as needed
          allowFullScreen
        ></iframe>
      </div>
    ),
  },
  {
    id: 1,
    name: "About Me",
    iconSrc: "/images/icons/AboutMe.png", // Placeholder profile picture URL
    position: { left: 50, top: 50 },
    content: (
      <div className="project-card">
        <div className="content">
          <h1 className="left-text">Hi, I'm Bardia!</h1>
          <img
            src="/images/AboutMe/Emoji.png"
            alt="Profile"
            className="right-image"
          />
        </div>

        <ScrollAnimation>
          <section>
            <h2 className="myTitle">
              I'm a Software Developer, from Vancouver, BC.
            </h2>
          </section>
        </ScrollAnimation>
        <ScrollAnimation>
          <section className="AboutMeSection">
            <h2 className="AboutMeTitle">About Me</h2>

            <p className="AboutMeText">
              I'm a Computer Systems Technology student at BCIT with a deep
              passion for <b>programming</b>, <b>application development</b>,
              and <b>software engineering</b>. My goal is to excel as a
              full-stack developer, merging my creative flair with my technical
              prowess. I am driven by the conviction that technology can be a
              powerful force for positive change in the world.
              <br />
              <br />
              My technical abilities are highlighted in the projects section of
              this website. In addition to these skills, I excel in{" "}
              <b>teamwork</b>, <b>communication</b>, <b>problem solving</b>, and
              stepping up to lead whenever necessary. When I'm not immersed in
              technology, I enjoy playing and watching basketball, snowboarding,
              watching movies, and relaxing with video games.
            </p>
          </section>
        </ScrollAnimation>

        <ScrollAnimation>
          <section className="TechnicalSkillsSection">
            <div className="skill-card">
              <h3>Programming Languages and Technologies</h3>
              <ul>
                <li>Java</li>
                <li>JavaScript</li>
                <li>C++</li>
                <li>C#</li>
                <li>Python</li>
                <li>Kotlin</li>
                <li>R</li>
              </ul>
            </div>
            <div className="skill-card">
              <h3>Web Development and Frameworks</h3>
              <ul>
                <li>HTML/CSS</li>
                <li>React</li>
                <li>Node.js</li>
                <li>Express</li>
                <li>Flask</li>
                <li>Bootstrap</li>
                <li>GIT</li>
                <li>SQL</li>
                <li>MongoDB</li>
                <li>Firestore</li>
              </ul>
            </div>
            <div className="skill-card">
              <h3>Applications</h3>
              <ul>
                <li>Android Studio</li>
                <li>Visual Studio</li>
                <li>IntelliJ</li>
                <li>PyCharm</li>
                <li>WebStorm</li>
                <li>Eclipse</li>
                <li>Figma</li>
              </ul>
            </div>
          </section>
        </ScrollAnimation>
        <ScrollAnimation>
          <section className="GetInTouchSection">
            <div className="left-content">
              <h2 className="GetInTouchTitle">Get In Touch</h2>
              <Gallery images={galleryImages} />
            </div>
            <div className="right-content">
              <div className="nested-app-links-about">
                <a
                  href="mailto:bardiatimouri@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email
                </a>
                <a
                  href="https://www.linkedin.com/in/bardia-timouri-979b87226/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
                <a
                  href="https://github.com/BardiaTiM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
                <a
                  href="/Bardia_Timouri_Resume.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download Resume
                </a>
              </div>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    ),
  },

  // Add more projects as needed
];

export default appsData;
