import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
  return (
    <div className="home-container1">
      <div className="login-container">
        <div className="image-placeholder"></div> {/* Image Placeholder */}
        <div className="title">Bardia Timouri's Portfolio Website</div>
        <Link to="/desktop">
          <button>Enter</button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
